import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import { authorizeUser, resetPassword, authLogout } from 'services/auth';
import { LOGIN_USER, LOGOUT_USER, RESET_PASSWORD } from '../constants';
import {
  loginUserSuccess,
  loginUserError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

// Worker Saga for authorizing user
function* authorizeUserSaga(action) {
  console.log(action.payload.user);
  try {
    const { email, password } = action.payload.user;
    const response = yield call(authorizeUser, email, password);
    if (response.status === 200) {
      localStorage.setItem('user', JSON.stringify(response.data.user));
      yield put(loginUserSuccess(response.data.user));
    } else {
      throw new Error('Error en la autorización del usuario.');
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}


// Worker Saga for resetting password
function* resetPasswordSaga(action) {
  try {
    const { userIde, oldPassword, newPassword } = action.payload;
    const response = yield call(
      resetPassword,
      userIde,
      oldPassword,
      newPassword
    );
    yield put(resetPasswordSuccess(response.data));
  } catch (error) {
    yield put(resetPasswordError(error.message));
  }
}

// Watcher Saga for LOGIN_USER action
function* watchAuthorizeUser() {
  yield takeEvery(LOGIN_USER, authorizeUserSaga);
}

// Watcher Saga for RESET_PASSWORD action
function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await authLogout()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export default function* rootSaga() {
  yield all([
    fork(watchAuthorizeUser),
    fork(watchLogoutUser),
    fork(watchResetPassword),
  ]);
}
