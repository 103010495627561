/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL_AUTH = `${process.env.REACT_APP_API_URL}/Account/authorize`;
const API_BASE_URL_PING = `${process.env.REACT_APP_API_URL}/Account/Ping`;
const API_BASE_URL_RESET = `${process.env.REACT_APP_API_URL}/Account/reset-password`;
const API_BASE_URL_REFRESH = `${process.env.REACT_APP_API_URL}/Account/refresh-token`;

export const pingToken = async () => {
  return axios.get(API_BASE_URL_PING);
};

export const authorizeUser = async (email, password) => {
  try {
    const response = await axios.post(API_BASE_URL_AUTH, {
      username: email,
      password,
    });
    if (response.status === 200) {
      Cookies.set('jwt', response.data.accessToken, { expires: 1 });
      Cookies.set('refresh', response.data.refreshToken, { expires: 1 });
    }
    return response;
  } catch (error) {
    throw new Error('Error en la autenticación del usuario.');
  }
};

export const resetPassword = async (userIde, oldPassword, newPassword) => {
  return axios.post(API_BASE_URL_RESET, { userIde, oldPassword, newPassword });
};

export const refreshToken = async (token) => {
  const response = await axios.post(`${API_BASE_URL_REFRESH}?token=${token}`);
  if (
    response.status === 200 &&
    response.data.accessToken &&
    response.data.refreshToken
  ) {
    Cookies.set('jwt', response.data.accessToken, { expires: 1 });
    Cookies.set('refresh', response.data.refreshToken, { expires: 1 });
  }
};

export const authLogout = () => {
  return new Promise((resolve, reject) => {
    try {
      console.log('logout');
      localStorage.removeItem('user');
      Cookies.remove('jwt');
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
